'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Bycrates_wSvg = require("../assets/bycrates_w.svg");
var ProjectC$BycratesPlatformUi = require("../ProjectC.bs.js");
var RouterLink$BycratesPlatformUi = require("../RouterLink/RouterLink.bs.js");

var logo = Bycrates_wSvg;

var menus = /* :: */[
  {
    href: /* [] */0,
    label: "Oversikt"
  },
  /* :: */[
    {
      href: /* :: */[
        "contacts",
        /* [] */0
      ],
      label: "Kontakter"
    },
    /* :: */[
      {
        href: /* :: */[
          "appointments",
          /* [] */0
        ],
        label: "Timeavtaler"
      },
      /* :: */[
        {
          href: /* :: */[
            "forms",
            /* [] */0
          ],
          label: "Skjemaer"
        },
        /* :: */[
          {
            href: /* :: */[
              "settings",
              /* [] */0
            ],
            label: "Prosjektinstillinger"
          },
          /* :: */[
            {
              href: /* :: */[
                "user_settings",
                /* [] */0
              ],
              label: "Brukerinstillinger"
            },
            /* [] */0
          ]
        ]
      ]
    ]
  ]
];

function Navbar(Props) {
  var project = React.useContext(ProjectC$BycratesPlatformUi.context);
  return React.createElement("nav", {
              className: "w-full fixed top-0 bg-gray-800 flex items-center justify-start px-6 py-3 text-white z-20 shadow"
            }, React.createElement("div", {
                  className: "mr-8",
                  id: "brand"
                }, React.createElement("img", {
                      className: "h-6",
                      src: logo
                    })), project === "" ? null : React.createElement("div", {
                    className: "flex space-x-8",
                    id: "navLinks"
                  }, $$Array.of_list(List.map((function (link) {
                              return React.createElement(RouterLink$BycratesPlatformUi.make, {
                                          href: link.href,
                                          className: "transition duration-200 text-sm p-1 px-2 rounded",
                                          activeClassName: "font-bold bg-gray-900",
                                          children: link.label,
                                          key: $$String.concat("/", link.href)
                                        });
                            }), menus))));
}

var make = Navbar;

exports.logo = logo;
exports.menus = menus;
exports.make = make;
/* logo Not a pure module */
