'use strict';

var React = require("react");
var ApiC$BycratesPlatformUi = require("../Api/ApiC.bs.js");
var Appointments$BycratesPlatformUi = require("./Appointments.bs.js");
var ResourceShow$BycratesPlatformUi = require("../Resource/ResourceShow.bs.js");

function NewAppointment(Props) {
  var api = React.useContext(ApiC$BycratesPlatformUi.context);
  var errorMessages = { };
  errorMessages["OverlappingAppointments"] = "Denne timeavtalen overlapper en annen timeavtale. Vennligst kontroller dato og tidspunkt";
  errorMessages["AppointmentBeforeNow"] = "Du prøver å opprette en timeavtale for en dato og/eller tidspunkt før nå";
  errorMessages["AppointmentHandlerNeedsRole"] = "Angitt behandler har ikke rollen \"Behandler\" i prosjektet";
  return React.createElement(ResourceShow$BycratesPlatformUi.make, {
              header: "Ny timeavtale",
              type_: /* Create */1,
              formBoxes: Appointments$BycratesPlatformUi.formBoxes(undefined, api),
              resourcePath: "/appointments",
              transformRequest: Appointments$BycratesPlatformUi.transformRequest,
              errorMessages: errorMessages
            });
}

var make = NewAppointment;

exports.make = make;
/* react Not a pure module */
