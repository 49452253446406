'use strict';

var React = require("react");

function Card(Props) {
  var children = Props.children;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: "bg-white p-8 w-full rounded shadow " + className
            }, children);
}

var make = Card;

exports.make = make;
/* react Not a pure module */
