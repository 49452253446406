'use strict';

var List = require("bs-platform/lib/js/list.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function pushRouter(e, href, query) {
  if (e !== undefined) {
    Caml_option.valFromOption(e).preventDefault();
  }
  return ReasonReactRouter.push("/" + ($$String.concat("/", href) + ("?" + query)));
}

function RouterLink(Props) {
  var href = Props.href;
  var classNameOpt = Props.className;
  var activeClassNameOpt = Props.activeClassName;
  var children = Props.children;
  var exactOpt = Props.exact;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var activeClassName = activeClassNameOpt !== undefined ? activeClassNameOpt : "";
  var exact = exactOpt !== undefined ? exactOpt : false;
  var url = ReasonReactRouter.useUrl(undefined, /* () */0);
  var isCurrentUrl = exact || !(List.length(url.path) > 0 && List.length(href) > 0) ? Caml_obj.caml_equal(url.path, href) : List.hd(url.path) === List.hd(href);
  return React.createElement("a", {
              className: "block cursor-pointer " + (className + (" " + (
                    isCurrentUrl ? activeClassName : ""
                  ))),
              href: $$String.concat("/", href) + ("?" + url.search),
              onClick: (function (e) {
                  return pushRouter(Caml_option.some(e), href, url.search);
                })
            }, children);
}

var make = RouterLink;

exports.pushRouter = pushRouter;
exports.make = make;
/* react Not a pure module */
