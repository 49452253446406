'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var H1$BycratesPlatformUi = require("../Headings/H1.bs.js");
var ApiC$BycratesPlatformUi = require("../Api/ApiC.bs.js");
var Alert$BycratesPlatformUi = require("../Alert/Alert.bs.js");
var Input$BycratesPlatformUi = require("../Input/Input.bs.js");
var Table$BycratesPlatformUi = require("../Table/Table.bs.js");
var Button$BycratesPlatformUi = require("../Button/Button.bs.js");
var Loader$BycratesPlatformUi = require("../Loader/Loader.bs.js");
var Checkbox$BycratesPlatformUi = require("../Checkbox/Checkbox.bs.js");
var RouterLink$BycratesPlatformUi = require("../RouterLink/RouterLink.bs.js");
var JsonDictUtils$BycratesPlatformUi = require("../Utils/JsonDictUtils.bs.js");
var PageContainer$BycratesPlatformUi = require("../PageContainer/PageContainer.bs.js");

function ResourceList(Props) {
  var header = Props.header;
  var resourceName = Props.resourceName;
  var newLabel = Props.newLabel;
  var cols = Props.cols;
  var filtersOpt = Props.filters;
  var defaultFiltersOpt = Props.defaultFilters;
  var rows = Props.rows;
  var filters = filtersOpt !== undefined ? filtersOpt : /* [] */0;
  var defaultFilters = defaultFiltersOpt !== undefined ? Caml_option.valFromOption(defaultFiltersOpt) : ({ });
  var match = React.useState((function () {
          return /* Loading */0;
        }));
  var setState_ = match[1];
  var state_ = match[0];
  var match$1 = React.useState((function () {
          return defaultFilters;
        }));
  var setFilterState = match$1[1];
  var filterState = match$1[0];
  var match$2 = React.useState((function () {
          return 0.0;
        }));
  var setTimeoutId = match$2[1];
  var timeoutId = match$2[0];
  var api = React.useContext(ApiC$BycratesPlatformUi.context);
  var fetchState = function (param) {
    var query = List.fold_left((function (query, filterName) {
            return query + ("filter[" + (filterName + ("]=" + (JsonDictUtils$BycratesPlatformUi.getOrEmpty(filterState, filterName) + "&"))));
          }), "", $$Array.to_list(Object.keys(filterState)));
    api.get("/" + (resourceName + ("?" + query))).then((function (res) {
              return Promise.resolve(Curry._1(setState_, (function (param) {
                                return /* Loaded */[res.data.data];
                              })));
            })).catch((function (param) {
            return Promise.resolve(Curry._1(setState_, (function (param) {
                              return /* CouldNotFetch */1;
                            })));
          }));
    return /* () */0;
  };
  React.useEffect((function () {
          fetchState(/* () */0);
          return ;
        }), ([]));
  var onFilterChange = function (e) {
    clearTimeout(timeoutId);
    var target = e.currentTarget;
    var name = target.id;
    var type_ = e.target.getAttribute("type");
    var value = type_ === "checkbox" ? Pervasives.string_of_bool(target.checked) : target.value;
    return Curry._1(setTimeoutId, (function (param) {
                  return setTimeout((function (param) {
                                return Curry._1(setFilterState, (function (x) {
                                              x[name] = value;
                                              fetchState(/* () */0);
                                              return x;
                                            }));
                              }), 300);
                }));
  };
  return React.createElement(PageContainer$BycratesPlatformUi.make, {
              children: typeof state_ === "number" ? (
                  state_ !== 0 ? React.createElement(Alert$BycratesPlatformUi.make, {
                          children: "Kunne ikke hente ressurs"
                        }) : React.createElement(Loader$BycratesPlatformUi.make, { })
                ) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                          className: "flex w-full justify-between"
                        }, React.createElement(H1$BycratesPlatformUi.make, {
                              children: header
                            }), React.createElement(RouterLink$BycratesPlatformUi.make, {
                              href: /* :: */[
                                resourceName,
                                /* :: */[
                                  "new",
                                  /* [] */0
                                ]
                              ],
                              children: React.createElement(Button$BycratesPlatformUi.make, {
                                    label: newLabel
                                  })
                            })), React.createElement("div", {
                          className: "flex justify-between items-center mt-4"
                        }, $$Array.of_list(List.map((function (filter) {
                                    var match = filter.type_;
                                    if (match) {
                                      return React.createElement(Checkbox$BycratesPlatformUi.make, {
                                                  name: filter.name,
                                                  label: filter.label,
                                                  initialValue: Pervasives.bool_of_string(JsonDictUtils$BycratesPlatformUi.getOr(filterState, filter.name, "false")),
                                                  onChange: onFilterChange
                                                });
                                    } else {
                                      return React.createElement(Input$BycratesPlatformUi.make, {
                                                  placeholder: filter.label,
                                                  name: filter.name,
                                                  onChange: onFilterChange,
                                                  initialValue: JsonDictUtils$BycratesPlatformUi.getOrEmpty(filterState, filter.name),
                                                  originalPlaceholder: true
                                                });
                                    }
                                  }), filters))), React.createElement(Table$BycratesPlatformUi.make, {
                          cols: cols,
                          rows: List.map(Curry.__1(rows), $$Array.to_list(state_[0])),
                          className: "mt-8"
                        }))
            });
}

var make = ResourceList;

exports.make = make;
/* react Not a pure module */
