'use strict';

var React = require("react");
var H1$BycratesPlatformUi = require("../Headings/H1.bs.js");
var PageContainer$BycratesPlatformUi = require("../PageContainer/PageContainer.bs.js");

function DashboardPage(Props) {
  React.useState((function () {
          return new Date();
        }));
  return React.createElement(PageContainer$BycratesPlatformUi.make, {
              children: React.createElement(H1$BycratesPlatformUi.make, {
                    children: "Her kommer oversikt snart"
                  })
            });
}

var make = DashboardPage;

exports.make = make;
/* react Not a pure module */
