'use strict';

var React = require("react");

function Button(Props) {
  var type_Opt = Props.type_;
  var classNameOpt = Props.className;
  var label = Props.label;
  var disabledOpt = Props.disabled;
  var type_ = type_Opt !== undefined ? type_Opt : "button";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return React.createElement("button", {
              className: "p-2 px-3 rounded text-white text-sm " + ((
                  disabled ? "bg-gray-500 cursor-not-allowed " : "bg-gray-800 "
                ) + className),
              disabled: disabled,
              type: type_
            }, label);
}

var make = Button;

exports.make = make;
/* react Not a pure module */
