'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Axios = require("bs-axios/src/axios.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Axios$1 = require("axios");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var LoaderCss = require("../loader.css");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var H1$BycratesPlatformUi = require("../Headings/H1.bs.js");
var ApiC$BycratesPlatformUi = require("../Api/ApiC.bs.js");
var Card$BycratesPlatformUi = require("../Card/Card.bs.js");
var TailwindCss = require("../generated/tailwind.css");
var Button$BycratesPlatformUi = require("../Button/Button.bs.js");
var Loader$BycratesPlatformUi = require("../Loader/Loader.bs.js");
var Navbar$BycratesPlatformUi = require("../Navbar/Navbar.bs.js");
var NewForm$BycratesPlatformUi = require("../Forms/NewForm.bs.js");
var ProjectC$BycratesPlatformUi = require("../ProjectC.bs.js");
var Settings$BycratesPlatformUi = require("../Settings/Settings.bs.js");
var FormsList$BycratesPlatformUi = require("../Forms/FormsList.bs.js");
var LoginPage$BycratesPlatformUi = require("../LoginPage/LoginPage.bs.js");
var AxiosUtils$BycratesPlatformUi = require("../Utils/AxiosUtils.bs.js");
var FormSchema$BycratesPlatformUi = require("../Forms/FormSchema.bs.js");
var NewContact$BycratesPlatformUi = require("../Contacts/NewContact.bs.js");
var RadioGroup$BycratesPlatformUi = require("../Radio/RadioGroup.bs.js");
var EditContact$BycratesPlatformUi = require("../Contacts/EditContact.bs.js");
var ShowContact$BycratesPlatformUi = require("../Contacts/ShowContact.bs.js");
var ContactsList$BycratesPlatformUi = require("../Contacts/ContactsList.bs.js");
var LocalStorage$BycratesPlatformUi = require("../Effects/LocalStorage.bs.js");
var NotFoundPage$BycratesPlatformUi = require("../NotFoundPage/NotFoundPage.bs.js");
var UserSettings$BycratesPlatformUi = require("../UserSettings/UserSettings.bs.js");
var DashboardPage$BycratesPlatformUi = require("../DashboardPage/DashboardPage.bs.js");
var NewAppointment$BycratesPlatformUi = require("../Appointments/NewAppointment.bs.js");
var ShowAppointment$BycratesPlatformUi = require("../Appointments/ShowAppointment.bs.js");
var AppointmentsList$BycratesPlatformUi = require("../Appointments/AppointmentsList.bs.js");
var ReactDayPicker_Style$BycratesPlatformUi = require("../bindings/ReactDayPicker/ReactDayPicker_Style.bs.js");

ReactDayPicker_Style$BycratesPlatformUi.$$import(/* () */0);

function Root(Props) {
  var match = React.useState((function () {
          return /* Loading */0;
        }));
  var setAuthStatus = match[1];
  var match$1 = React.useState((function () {
          return /* NotSelected */0;
        }));
  var setProjectSlug = match$1[1];
  var projectSlug = match$1[0];
  var match$2 = React.useState((function () {
          return /* NoPValue */0;
        }));
  var setProjectValue = match$2[1];
  var projectValue = match$2[0];
  var match$3 = React.useState((function () {
          return /* ProjectsLoading */0;
        }));
  var setProjects = match$3[1];
  var projects = match$3[0];
  var url = ReasonReactRouter.useUrl(undefined, /* () */0);
  var createApi = function (params) {
    var headers = Axios.$$Headers.fromObj({
          "Content-Type": "application/json"
        });
    return Axios$1.create({
                baseURL: "/api",
                headers: headers,
                params: params,
                withCredentials: true
              });
  };
  var noProjectApi = createApi({
        _: ""
      });
  React.useEffect((function () {
          if (projectSlug) {
            var p = projectSlug[0];
            noProjectApi.get("/projects/" + p).then((function (param) {
                      return Promise.resolve(ReasonReactRouter.push("?projectSlug=" + p));
                    })).catch((function (err) {
                    if (AxiosUtils$BycratesPlatformUi.getErrorStatus(err) === 404) {
                      LocalStorage$BycratesPlatformUi.set("projectSlug", "");
                      ReasonReactRouter.push("/");
                      window.location.reload();
                    }
                    return Promise.resolve(/* () */0);
                  }));
          }
          return ;
        }), Caml_array.caml_make_vect(1, projectSlug));
  React.useEffect((function () {
          var queries = $$String.split_on_char(/* "," */44, url.search);
          List.iter((function (query) {
                  var kv = $$String.split_on_char(/* "=" */61, query);
                  if (List.length(kv) === 2 && List.hd(kv) === "projectSlug") {
                    var pslug = List.nth(kv, 1);
                    if (pslug !== "") {
                      LocalStorage$BycratesPlatformUi.set("projectSlug", pslug);
                      return Curry._1(setProjectSlug, (function (param) {
                                    return /* Selected */[pslug];
                                  }));
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                }), queries);
          return ;
        }), Caml_array.caml_make_vect(1, url.search));
  React.useEffect((function () {
          var match = LocalStorage$BycratesPlatformUi.get("projectSlug");
          var exit = 0;
          if (match !== undefined) {
            var slug = match;
            if (slug === "") {
              exit = 1;
            } else {
              Curry._1(setProjectSlug, (function (param) {
                      return /* Selected */[slug];
                    }));
            }
          } else {
            exit = 1;
          }
          if (exit === 1) {
            noProjectApi.get("/projects").then((function (res) {
                    var projs = res.data.data;
                    if (projs.length === 1) {
                      var frst = Caml_array.caml_array_get(projs, 0);
                      LocalStorage$BycratesPlatformUi.set("projectSlug", frst.slug);
                      Curry._1(setProjectSlug, (function (param) {
                              return /* Selected */[frst.slug];
                            }));
                    } else {
                      Curry._1(setProjects, (function (param) {
                              return /* Projects */[res.data.data];
                            }));
                    }
                    return Promise.resolve(/* () */0);
                  }));
          }
          noProjectApi.get("/me").then((function (param) {
                    return Promise.resolve(Curry._1(setAuthStatus, (function (param) {
                                      return /* Authenticated */2;
                                    })));
                  })).catch((function (err) {
                  if (AxiosUtils$BycratesPlatformUi.getErrorStatus(err) === 401) {
                    Curry._1(setAuthStatus, (function (param) {
                            return /* Unauthenticated */1;
                          }));
                    Curry._1(setProjectSlug, (function (param) {
                            return /* NotSelected */0;
                          }));
                  }
                  return Promise.resolve(/* () */0);
                }));
          return ;
        }), ([]));
  var setProjectVal = function (value) {
    return Curry._1(setProjectValue, (function (param) {
                  return /* PValue */[value];
                }));
  };
  var setProject = function (evt) {
    evt.preventDefault();
    if (projectValue) {
      var x = projectValue[0];
      LocalStorage$BycratesPlatformUi.set("projectSlug", x);
      return Curry._1(setProjectSlug, (function (param) {
                    return /* Selected */[x];
                  }));
    } else {
      return /* () */0;
    }
  };
  var tmp;
  switch (match[0]) {
    case /* Loading */0 :
        tmp = React.createElement("div", {
              className: "flex-grow font-bold text-center text-xl mt-12"
            }, React.createElement(Loader$BycratesPlatformUi.make, { }));
        break;
    case /* Unauthenticated */1 :
        tmp = React.createElement("div", {
              className: "flex-grow mt-16"
            }, React.createElement("div", {
                  className: "mt-12 flex items-center justify-center h-full w-full"
                }, React.createElement(LoginPage$BycratesPlatformUi.make, { })));
        break;
    case /* Authenticated */2 :
        if (projectSlug) {
          var slug = projectSlug[0];
          var match$4 = url.path;
          var tmp$1;
          var exit = 0;
          if (match$4) {
            switch (match$4[0]) {
              case "appointments" :
                  var match$5 = match$4[1];
                  if (match$5) {
                    var id = match$5[0];
                    if (id === "new") {
                      if (match$5[1]) {
                        exit = 1;
                      } else {
                        tmp$1 = React.createElement(NewAppointment$BycratesPlatformUi.make, { });
                      }
                    } else if (match$5[1]) {
                      exit = 1;
                    } else {
                      tmp$1 = React.createElement(ShowAppointment$BycratesPlatformUi.make, {
                            id: id
                          });
                    }
                  } else {
                    tmp$1 = React.createElement(AppointmentsList$BycratesPlatformUi.make, { });
                  }
                  break;
              case "contacts" :
                  var match$6 = match$4[1];
                  if (match$6) {
                    var id$1 = match$6[0];
                    var exit$1 = 0;
                    if (id$1 === "new" && !match$6[1]) {
                      tmp$1 = React.createElement(NewContact$BycratesPlatformUi.make, { });
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      var match$7 = match$6[1];
                      if (match$7) {
                        if (match$7[0] === "edit" && !match$7[1]) {
                          tmp$1 = React.createElement(EditContact$BycratesPlatformUi.make, {
                                id: id$1
                              });
                        } else {
                          exit = 1;
                        }
                      } else {
                        tmp$1 = React.createElement(ShowContact$BycratesPlatformUi.make, {
                              id: id$1
                            });
                      }
                    }
                    
                  } else {
                    tmp$1 = React.createElement(ContactsList$BycratesPlatformUi.make, { });
                  }
                  break;
              case "forms" :
                  var match$8 = match$4[1];
                  if (match$8) {
                    var id$2 = match$8[0];
                    if (id$2 === "new") {
                      if (match$8[1]) {
                        exit = 1;
                      } else {
                        tmp$1 = React.createElement(NewForm$BycratesPlatformUi.make, { });
                      }
                    } else if (match$8[1]) {
                      exit = 1;
                    } else {
                      tmp$1 = React.createElement(FormSchema$BycratesPlatformUi.make, {
                            id: id$2,
                            mode: /* Edit */1
                          });
                    }
                  } else {
                    tmp$1 = React.createElement(FormsList$BycratesPlatformUi.make, { });
                  }
                  break;
              case "settings" :
                  if (match$4[1]) {
                    exit = 1;
                  } else {
                    tmp$1 = React.createElement(Settings$BycratesPlatformUi.make, { });
                  }
                  break;
              case "user_settings" :
                  if (match$4[1]) {
                    exit = 1;
                  } else {
                    tmp$1 = React.createElement(UserSettings$BycratesPlatformUi.make, { });
                  }
                  break;
              default:
                exit = 1;
            }
          } else {
            tmp$1 = React.createElement(DashboardPage$BycratesPlatformUi.make, { });
          }
          if (exit === 1) {
            tmp$1 = React.createElement(NotFoundPage$BycratesPlatformUi.make, { });
          }
          tmp = React.createElement(ApiC$BycratesPlatformUi.make, ApiC$BycratesPlatformUi.makeProps(createApi({
                        projectSlug: slug
                      }), React.createElement(ProjectC$BycratesPlatformUi.make, ProjectC$BycratesPlatformUi.makeProps(slug, React.createElement("div", {
                                className: "h-full flex-grow"
                              }, React.createElement("div", {
                                    className: "w-full h-full"
                                  }, React.createElement("div", {
                                        className: "w-full xl:max-w-6xl m-auto pt-16"
                                      }, tmp$1))), /* () */0)), /* () */0));
        } else {
          tmp = React.createElement("div", {
                className: "max-w-md w-full ml-auto mr-auto mt-12"
              }, React.createElement(H1$BycratesPlatformUi.make, {
                    className: "text-center mb-12 pt-12",
                    children: "Velg prosjektet du vil åpne"
                  }), React.createElement(Card$BycratesPlatformUi.make, {
                    children: projects ? React.createElement("form", {
                            onSubmit: setProject
                          }, React.createElement(RadioGroup$BycratesPlatformUi.make, {
                                name: "project",
                                boxes: $$Array.to_list($$Array.map((function (p) {
                                            return {
                                                    value: p.slug,
                                                    label: p.name
                                                  };
                                          }), projects[0])),
                                className: "mb-8",
                                setValue: setProjectVal
                              }), React.createElement(Button$BycratesPlatformUi.make, {
                                type_: "submit",
                                label: "Velg"
                              })) : React.createElement(Loader$BycratesPlatformUi.make, { })
                  }));
        }
        break;
    
  }
  return React.createElement("div", {
              className: "min-h-screen min-w-screen font-sans bg-background flex flex-col antialiased overflow-hidden"
            }, projectSlug ? React.createElement(ProjectC$BycratesPlatformUi.make, ProjectC$BycratesPlatformUi.makeProps(projectSlug[0], React.createElement(Navbar$BycratesPlatformUi.make, { }), /* () */0)) : React.createElement(Navbar$BycratesPlatformUi.make, { }), tmp);
}

var tailwind = /* () */0;

var loader = /* () */0;

var make = Root;

exports.tailwind = tailwind;
exports.loader = loader;
exports.make = make;
/*  Not a pure module */
