'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var RouterLink$BycratesPlatformUi = require("../RouterLink/RouterLink.bs.js");

function Table(Props) {
  var cols = Props.cols;
  var rows = Props.rows;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: "grid bg-white shadow-sm rounded-sm w-full grid-cols-" + (String(List.length(cols)) + (" " + className))
            }, $$Array.of_list(List.map((function (col) {
                        return React.createElement("div", {
                                    className: "border-b font-bold text-sm px-4 py-3"
                                  }, col);
                      }), cols)), $$Array.of_list(List.map((function (row) {
                        return $$Array.of_list(List.map((function (rowObj) {
                                          var tmp;
                                          if (rowObj.tag) {
                                            var x = rowObj[0];
                                            tmp = React.createElement("div", {
                                                  className: "inline-flex"
                                                }, React.createElement(RouterLink$BycratesPlatformUi.make, {
                                                      href: x.href,
                                                      className: "text-primary",
                                                      children: x.label
                                                    }));
                                          } else {
                                            var x$1 = rowObj[0];
                                            tmp = (x$1 == null) ? React.createElement("span", {
                                                    className: "italic"
                                                  }, "Blank") : x$1;
                                          }
                                          return React.createElement("div", {
                                                      className: "px-4 py-3 text-sm text-gray-900"
                                                    }, tmp);
                                        }), row));
                      }), rows)));
}

var make = Table;

exports.make = make;
/* react Not a pure module */
