'use strict';

var React = require("react");
var Contacts$BycratesPlatformUi = require("./Contacts.bs.js");
var ResourceShow$BycratesPlatformUi = require("../Resource/ResourceShow.bs.js");

function NewContact(Props) {
  return React.createElement(ResourceShow$BycratesPlatformUi.make, {
              header: "Ny kontakt",
              type_: /* Create */1,
              formBoxes: Contacts$BycratesPlatformUi.formBoxes,
              resourcePath: "/contacts"
            });
}

var make = NewContact;

exports.make = make;
/* react Not a pure module */
