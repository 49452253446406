'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");

function RadioGroup(Props) {
  var inlineOpt = Props.inline;
  var name = Props.name;
  var boxes = Props.boxes;
  var classNameOpt = Props.className;
  var setValue = Props.setValue;
  var inline = inlineOpt !== undefined ? inlineOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useState((function () {
          return "";
        }));
  var setCheckedId = match[1];
  var checkedId = match[0];
  React.useEffect((function () {
          Curry._1(setValue, checkedId);
          return ;
        }), Caml_array.caml_make_vect(1, checkedId));
  return React.createElement("div", {
              className: (
                inline ? "flex space-x-12 " : ""
              ) + className
            }, $$Array.of_list(List.mapi((function (index, box) {
                        return React.createElement("div", {
                                    key: box.value,
                                    className: index !== 0 || index === (List.length(boxes) - 1 | 0) ? "" : "mb-3"
                                  }, React.createElement("label", {
                                        className: "flex items-center focus:outline-none text-sm",
                                        tabIndex: 0,
                                        htmlFor: name + box.value
                                      }, React.createElement("div", {
                                            className: "w-4 h-4 rounded-lg shadow p-1 bg-white cursor-pointer transition duration-200 hover:shadow-lg focus:shadow-lg"
                                          }, React.createElement("div", {
                                                className: "w-full h-full rounded-lg transition duration-100 " + (
                                                  checkedId === box.value ? "bg-gray-800" : "bg-transparent"
                                                )
                                              })), React.createElement("div", {
                                            className: "pl-3"
                                          }, box.label)), React.createElement("input", {
                                        className: "hidden",
                                        hidden: true,
                                        id: name + box.value,
                                        checked: checkedId === box.value,
                                        name: box.value,
                                        type: "radio",
                                        onChange: (function (e) {
                                            return Curry._1(setCheckedId, e.target.name);
                                          })
                                      }));
                      }), boxes)));
}

var make = RadioGroup;

exports.make = make;
/* react Not a pure module */
