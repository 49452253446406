'use strict';

var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var ResourceList$BycratesPlatformUi = require("../Resource/ResourceList.bs.js");

function ContactsList(Props) {
  return React.createElement(ResourceList$BycratesPlatformUi.make, {
              header: "Kontakter",
              resourceName: "contacts",
              newLabel: "Ny kontakt",
              cols: /* :: */[
                "Fornavn",
                /* :: */[
                  "Etternavn",
                  /* :: */[
                    "E-post",
                    /* :: */[
                      "Telefonnummer",
                      /* :: */[
                        "",
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ],
              filters: /* :: */[
                {
                  name: "fullName",
                  label: "Fullt navn",
                  type_: /* Input */0
                },
                /* [] */0
              ],
              rows: (function (row) {
                  return /* :: */[
                          /* Raw */Block.__(0, [row.givenName]),
                          /* :: */[
                            /* Raw */Block.__(0, [row.familyName]),
                            /* :: */[
                              /* Raw */Block.__(0, [row.emailAddress]),
                              /* :: */[
                                /* Raw */Block.__(0, [row.phoneNumber]),
                                /* :: */[
                                  /* Action */Block.__(1, [{
                                        label: "Vis",
                                        href: /* :: */[
                                          "contacts",
                                          /* :: */[
                                            row.id.toString(),
                                            /* [] */0
                                          ]
                                        ],
                                        type_: "primary"
                                      }]),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ];
                })
            });
}

var make = ContactsList;

exports.make = make;
/* react Not a pure module */
