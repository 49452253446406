'use strict';

var React = require("react");
var NotFound$BycratesPlatformUi = require("../Illustrations/NotFound/NotFound.bs.js");

function NotFoundPage(Props) {
  return React.createElement("div", {
              className: "mt-16 w-full flex flex-col items-center justify-center"
            }, React.createElement(NotFound$BycratesPlatformUi.make, { }), React.createElement("h1", {
                  className: "leading-loose font-bold text-3xl pt-24"
                }, "Denne siden kunne ikke bli funnet :("));
}

var make = NotFoundPage;

exports.make = make;
/* react Not a pure module */
