'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");

function Checkbox(Props) {
  var name = Props.name;
  var label = Props.label;
  var initialValueOpt = Props.initialValue;
  var onChange = Props.onChange;
  var checked = Props.checked;
  var initialValue = initialValueOpt !== undefined ? initialValueOpt : false;
  var match = React.useState((function () {
          return initialValue;
        }));
  var setCheckedInterim = match[1];
  var checkedInterim = match[0];
  var onChangeInterim = function (e) {
    var target = e.currentTarget;
    Curry._1(setCheckedInterim, target.checked);
    return Curry._1(onChange, e);
  };
  React.useEffect((function () {
          if (checked !== undefined) {
            var x = checked;
            Curry._1(setCheckedInterim, (function (param) {
                    return x;
                  }));
          }
          return ;
        }), Caml_array.caml_make_vect(1, checked));
  return React.createElement("div", undefined, React.createElement("label", {
                  className: "flex items-center focus:outline-none text-sm",
                  tabIndex: 0,
                  htmlFor: name
                }, React.createElement("div", {
                      className: "w-4 h-4 rounded shadow p-1 bg-white cursor-pointer transition duration-200 hover:shadow-lg focus:shadow-lg"
                    }, React.createElement("div", {
                          className: "w-full h-full transition duration-100 " + (
                            checkedInterim ? "bg-gray-800" : "bg-transparent"
                          )
                        })), React.createElement("div", {
                      className: "pl-3"
                    }, label)), React.createElement("input", {
                  className: "hidden",
                  hidden: true,
                  id: name,
                  checked: checkedInterim,
                  name: name,
                  type: "checkbox",
                  onChange: onChangeInterim
                }));
}

var make = Checkbox;

exports.make = make;
/* react Not a pure module */
