'use strict';

var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var ResourceList$BycratesPlatformUi = require("../Resource/ResourceList.bs.js");

function FormsList(Props) {
  return React.createElement(ResourceList$BycratesPlatformUi.make, {
              header: "Skjemaer",
              resourceName: "forms",
              newLabel: "Ny skjema",
              cols: /* :: */[
                "Navn",
                /* :: */[
                  "",
                  /* [] */0
                ]
              ],
              rows: (function (row) {
                  return /* :: */[
                          /* Raw */Block.__(0, [row.name]),
                          /* :: */[
                            /* Action */Block.__(1, [{
                                  label: "Vis",
                                  href: /* :: */[
                                    "forms",
                                    /* :: */[
                                      row.id.toString(),
                                      /* [] */0
                                    ]
                                  ],
                                  type_: "primary"
                                }]),
                            /* [] */0
                          ]
                        ];
                })
            });
}

var make = FormsList;

exports.make = make;
/* react Not a pure module */
