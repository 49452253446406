'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");

function set(key, value) {
  localStorage.setItem(key, value);
  return /* () */0;
}

function get(key) {
  return Caml_option.null_to_opt(localStorage.getItem(key));
}

exports.set = set;
exports.get = get;
/* No side effect */
