'use strict';

var React = require("react");
var ProjectC$BycratesPlatformUi = require("../ProjectC.bs.js");
var ResourceShow$BycratesPlatformUi = require("../Resource/ResourceShow.bs.js");

function UserSettings(Props) {
  React.useContext(ProjectC$BycratesPlatformUi.context);
  return React.createElement(ResourceShow$BycratesPlatformUi.make, {
              showSavedNoRedirect: true,
              header: "Brukerinstillinger",
              type_: /* Edit */2,
              formBoxes: /* :: */[
                {
                  header: "Passord",
                  inputs: /* :: */[
                    ResourceShow$BycratesPlatformUi.makeFormInput(undefined, true, "password", undefined, undefined, undefined, undefined, undefined, undefined, undefined, "currentPassword", "Nåværende passord"),
                    /* :: */[
                      ResourceShow$BycratesPlatformUi.makeFormInput(undefined, true, "password", undefined, undefined, undefined, undefined, undefined, undefined, undefined, "newPassword", "Ny passord"),
                      /* :: */[
                        ResourceShow$BycratesPlatformUi.makeFormInput(undefined, true, "password", undefined, undefined, undefined, undefined, undefined, undefined, undefined, "confirmNewPassword", "Bekreft passord"),
                        /* [] */0
                      ]
                    ]
                  ]
                },
                /* [] */0
              ],
              resourcePath: "/me/change_password",
              noLoad: true,
              method: /* Create */1
            });
}

var make = UserSettings;

exports.make = make;
/* react Not a pure module */
