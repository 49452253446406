'use strict';

var React = require("react");
var ResourceShow$BycratesPlatformUi = require("../Resource/ResourceShow.bs.js");

function NewForm(Props) {
  return React.createElement(ResourceShow$BycratesPlatformUi.make, {
              header: "Ny skjema",
              type_: /* Create */1,
              formBoxes: /* :: */[
                {
                  header: "Detaljer",
                  inputs: /* :: */[
                    ResourceShow$BycratesPlatformUi.makeFormInput(undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "formName", "Navn"),
                    /* :: */[
                      ResourceShow$BycratesPlatformUi.makeFormInput(undefined, true, "radio", undefined, undefined, undefined, undefined, undefined, /* :: */[
                            {
                              value: "COVID19",
                              label: "COVID19"
                            },
                            /* [] */0
                          ], undefined, "template", "Skjemamal"),
                      /* [] */0
                    ]
                  ]
                },
                /* [] */0
              ],
              resourcePath: "/forms"
            });
}

var make = NewForm;

exports.make = make;
/* react Not a pure module */
