'use strict';

var React = require("react");

function Alert(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var type_Opt = Props.type_;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var type_ = type_Opt !== undefined ? type_Opt : /* Error */1;
  return React.createElement("div", {
              className: "w-full rounded p-2 text-sm border" + ((
                  type_ ? " bg-red-100 border-red-500 " : " bg-green-100 border-green-500 "
                ) + className)
            }, children);
}

var make = Alert;

exports.make = make;
/* react Not a pure module */
