'use strict';

var React = require("react");

function H5(Props) {
  var classNameOpt = Props.className;
  var lightOpt = Props.light;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var light = lightOpt !== undefined ? lightOpt : false;
  return React.createElement("h5", {
              className: "text-normal " + ((
                  light ? "font-light " : "font-medium "
                ) + className)
            }, children);
}

var make = H5;

exports.make = make;
/* react Not a pure module */
