'use strict';


function getErrorObj(err) {
  return err;
}

function getErrorResponse(err) {
  return err.response;
}

function getErrorResponseData(err) {
  return err.response.data.err;
}

function getErrorStatus(err) {
  return err.response.status;
}

exports.getErrorObj = getErrorObj;
exports.getErrorResponse = getErrorResponse;
exports.getErrorResponseData = getErrorResponseData;
exports.getErrorStatus = getErrorStatus;
/* No side effect */
