'use strict';

var React = require("react");
var ProjectC$BycratesPlatformUi = require("../ProjectC.bs.js");
var ResourceShow$BycratesPlatformUi = require("../Resource/ResourceShow.bs.js");

function Settings(Props) {
  var project = React.useContext(ProjectC$BycratesPlatformUi.context);
  return React.createElement(ResourceShow$BycratesPlatformUi.make, {
              showSavedNoRedirect: true,
              header: "Prosjektinstillinger",
              type_: /* Edit */2,
              formBoxes: /* :: */[
                {
                  header: "Generelt",
                  inputs: /* :: */[
                    ResourceShow$BycratesPlatformUi.makeFormInput(undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "friendlyName", "Visningsnavn"),
                    /* [] */0
                  ]
                },
                /* :: */[
                  {
                    header: "Skjemaer",
                    inputs: /* :: */[
                      ResourceShow$BycratesPlatformUi.makeFormInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "logo", "Logo-URL"),
                      /* [] */0
                    ]
                  },
                  /* [] */0
                ]
              ],
              resourcePath: "/projects/" + project
            });
}

var make = Settings;

exports.make = make;
/* react Not a pure module */
