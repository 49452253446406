'use strict';

var React = require("react");
var H2$BycratesPlatformUi = require("../Headings/H2.bs.js");

function FlexActions(Props) {
  var header = Props.header;
  var children = Props.children;
  return React.createElement("div", {
              className: "w-full flex items-center justify-between mb-8"
            }, React.createElement(H2$BycratesPlatformUi.make, {
                  children: header
                }), React.createElement("div", {
                  className: "flex items-center justify-between space-x-4 text-sm"
                }, children));
}

var make = FlexActions;

exports.make = make;
/* react Not a pure module */
