'use strict';

var ResourceShow$BycratesPlatformUi = require("../Resource/ResourceShow.bs.js");

var formBoxes_000 = {
  header: "Kontaktinformasjon",
  inputs: /* :: */[
    ResourceShow$BycratesPlatformUi.makeFormInput(undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "givenName", "Fornavn"),
    /* :: */[
      ResourceShow$BycratesPlatformUi.makeFormInput(undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "familyName", "Etternavn"),
      /* :: */[
        ResourceShow$BycratesPlatformUi.makeFormInput(undefined, undefined, "email", undefined, undefined, undefined, undefined, undefined, undefined, undefined, "emailAddress", "E-post adresse"),
        /* :: */[
          ResourceShow$BycratesPlatformUi.makeFormInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "phoneNumber", "Telefonnummer"),
          /* [] */0
        ]
      ]
    ]
  ]
};

var formBoxes_001 = /* :: */[
  {
    header: "Faktureringsinformasjon",
    inputs: /* :: */[
      ResourceShow$BycratesPlatformUi.makeFormInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "addressLine1", "Gateadresse"),
      /* :: */[
        ResourceShow$BycratesPlatformUi.makeFormInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "addressLine2", "Gateaddresse 2"),
        /* :: */[
          ResourceShow$BycratesPlatformUi.makeFormInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "city", "Poststed"),
          /* :: */[
            ResourceShow$BycratesPlatformUi.makeFormInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "postalCode", "Postnummer"),
            /* [] */0
          ]
        ]
      ]
    ]
  },
  /* [] */0
];

var formBoxes = /* :: */[
  formBoxes_000,
  formBoxes_001
];

exports.formBoxes = formBoxes;
/* formBoxes Not a pure module */
