'use strict';

var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Appointments$BycratesPlatformUi = require("./Appointments.bs.js");
var ResourceList$BycratesPlatformUi = require("../Resource/ResourceList.bs.js");

function AppointmentsList(Props) {
  var defaultFilters = { };
  defaultFilters["hideCancelled"] = "true";
  defaultFilters["hideFinished"] = "true";
  return React.createElement(ResourceList$BycratesPlatformUi.make, {
              header: "Timeavtaler",
              resourceName: "appointments",
              newLabel: "Ny timeavtale",
              cols: /* :: */[
                "Kontakt",
                /* :: */[
                  "Dato",
                  /* :: */[
                    "Behandler",
                    /* :: */[
                      "Status",
                      /* :: */[
                        "",
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ],
              filters: /* :: */[
                {
                  name: "contactName",
                  label: "Kontakt navn",
                  type_: /* Input */0
                },
                /* :: */[
                  {
                    name: "hideCancelled",
                    label: "Skjul avbestilte avtaler",
                    type_: /* Checkbox */1
                  },
                  /* :: */[
                    {
                      name: "hideFinished",
                      label: "Skjul ferdige avtaler",
                      type_: /* Checkbox */1
                    },
                    /* [] */0
                  ]
                ]
              ],
              defaultFilters: defaultFilters,
              rows: (function (row) {
                  return /* :: */[
                          /* Action */Block.__(1, [{
                                label: row.contactName,
                                href: /* :: */[
                                  "contacts",
                                  /* :: */[
                                    row.contactId.toString(),
                                    /* [] */0
                                  ]
                                ],
                                type_: "primary"
                              }]),
                          /* :: */[
                            /* Raw */Block.__(0, [new Date(row.appointmentTimestamp).toLocaleString()]),
                            /* :: */[
                              /* Action */Block.__(1, [{
                                    label: row.appointmentHandlerName,
                                    href: /* :: */[
                                      "users",
                                      /* :: */[
                                        row.appointmentHandlerId.toString(),
                                        /* [] */0
                                      ]
                                    ],
                                    type_: "primary"
                                  }]),
                              /* :: */[
                                /* Raw */Block.__(0, [Appointments$BycratesPlatformUi.statusToString(/* tuple */[
                                          Caml_option.nullable_to_opt(row.canceledAt),
                                          Caml_option.nullable_to_opt(row.markedAbsentAt),
                                          row.isFinished
                                        ])]),
                                /* :: */[
                                  /* Action */Block.__(1, [{
                                        label: "Vis",
                                        href: /* :: */[
                                          "appointments",
                                          /* :: */[
                                            row.id.toString(),
                                            /* [] */0
                                          ]
                                        ],
                                        type_: "primary"
                                      }]),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ];
                })
            });
}

var make = AppointmentsList;

exports.make = make;
/* react Not a pure module */
