'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var H3$BycratesPlatformUi = require("../Headings/H3.bs.js");
var H5$BycratesPlatformUi = require("../Headings/H5.bs.js");
var ApiC$BycratesPlatformUi = require("../Api/ApiC.bs.js");
var Card$BycratesPlatformUi = require("../Card/Card.bs.js");
var Loader$BycratesPlatformUi = require("../Loader/Loader.bs.js");
var Appointments$BycratesPlatformUi = require("./Appointments.bs.js");
var ResourceShow$BycratesPlatformUi = require("../Resource/ResourceShow.bs.js");
var JsonDictUtils$BycratesPlatformUi = require("../Utils/JsonDictUtils.bs.js");

function ShowAppointment(Props) {
  var id = Props.id;
  var api = React.useContext(ApiC$BycratesPlatformUi.context);
  var match = React.useState((function () {
          return /* Loading */0;
        }));
  var setFormAnswers = match[1];
  var formAnswers = match[0];
  React.useEffect((function () {
          api.get("/appointments/" + (id + "/form_answers")).then((function (res) {
                  return Promise.resolve(Curry._1(setFormAnswers, (function (param) {
                                    return /* Loaded */[res.data.data];
                                  })));
                }));
          return ;
        }), ([]));
  var formSection = function (state) {
    var tmp;
    if (state) {
      var x = state[0];
      tmp = React.createElement(Card$BycratesPlatformUi.make, {
            children: null
          }, React.createElement(H3$BycratesPlatformUi.make, {
                children: "Status"
              }), React.createElement(H5$BycratesPlatformUi.make, {
                className: "mt-2",
                light: true,
                children: Appointments$BycratesPlatformUi.statusToString(/* tuple */[
                      JsonDictUtils$BycratesPlatformUi.get(x, "canceledAt"),
                      JsonDictUtils$BycratesPlatformUi.get(x, "markedAbsentAt"),
                      JsonDictUtils$BycratesPlatformUi.getBoolOrFalse(x, "isFinished")
                    ])
              }));
    } else {
      tmp = null;
    }
    var tmp$1;
    if (formAnswers) {
      var x$1 = formAnswers[0];
      tmp$1 = x$1.forms.length === 0 ? null : React.createElement("div", {
              className: "mt-6"
            }, React.createElement(H3$BycratesPlatformUi.make, {
                  children: "Skjemaer"
                }), $$Array.map((function (form) {
                    var disqualifyingEntries = form.answers.filter((function (x) {
                            if (x.answerYesNo && x.disqualifyingYes) {
                              return true;
                            } else if (x.answerYesNo) {
                              return false;
                            } else {
                              return x.disqualifyingNo;
                            }
                          }));
                    var disqualified = disqualifyingEntries.length !== 0;
                    var name = disqualified ? form.name + " (Diskvalifisert)" : form.name;
                    return React.createElement(Card$BycratesPlatformUi.make, {
                                children: null,
                                className: "mt-4"
                              }, React.createElement(H3$BycratesPlatformUi.make, {
                                    className: disqualified ? "text-red-500" : "",
                                    children: name
                                  }), React.createElement("div", {
                                    className: "mt-4 space-y-12"
                                  }, $$Array.map((function (answer) {
                                          return React.createElement("div", undefined, React.createElement(H5$BycratesPlatformUi.make, {
                                                          light: true,
                                                          children: answer.question
                                                        }), React.createElement(H5$BycratesPlatformUi.make, {
                                                          children: answer.answerYesNo ? React.createElement("span", {
                                                                  className: answer.disqualifyingYes ? "text-red-500" : ""
                                                                }, "Ja") : React.createElement("span", {
                                                                  className: answer.disqualifyingNo ? "text-red-500" : ""
                                                                }, "Nei")
                                                        }));
                                        }), form.answers)));
                  }), x$1.forms));
    } else {
      tmp$1 = React.createElement(Loader$BycratesPlatformUi.make, { });
    }
    return React.createElement("div", {
                className: "mt-6"
              }, tmp, tmp$1);
  };
  var hideActionsIf = function (loadedState) {
    var status = JsonDictUtils$BycratesPlatformUi.get(loadedState, "canceledAt");
    var absent = JsonDictUtils$BycratesPlatformUi.get(loadedState, "markedAbsentAt");
    var isFinished = JsonDictUtils$BycratesPlatformUi.getBoolOrFalse(loadedState, "isFinished");
    if (status !== undefined || absent !== undefined || isFinished) {
      return true;
    } else {
      return false;
    }
  };
  var absent = function (param) {
    if (confirm("Er du sikker på at du vil markere denne timen som \"Ikke møtt\"?")) {
      api.delete("/appointments/" + (id + "/absent")).then((function (param) {
              return Promise.resolve((window.location.reload(), /* () */0));
            }));
      return /* () */0;
    } else {
      return /* () */0;
    }
  };
  var customActions = function (loadedState) {
    var appointmentTimestamp = JsonDictUtils$BycratesPlatformUi.get(loadedState, "appointmentTimestamp");
    if (appointmentTimestamp !== undefined) {
      var parsed = new Date(appointmentTimestamp);
      var aptunix = parsed.getTime();
      if (Date.now() > aptunix) {
        return React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: "text-red-500 cursor-pointer",
                        onClick: absent
                      }, "Ikke møtt"));
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  return React.createElement(ResourceShow$BycratesPlatformUi.make, {
              header: "Vis timeavtale",
              type_: /* Show */0,
              formBoxes: Appointments$BycratesPlatformUi.formBoxes(true, api),
              resourcePath: "/appointments/" + id,
              deleteButtonLabel: "Avbestill",
              customSection: formSection,
              hideActionsIf: hideActionsIf,
              hideEdit: true,
              customActions: customActions,
              deleteMessage: "Er du sikker på at du vil markere denne timeavtalen som avbestilt?"
            });
}

var make = ShowAppointment;

exports.make = make;
/* react Not a pure module */
