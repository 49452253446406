'use strict';

var $$Array = require("bs-platform/lib/js/array.js");

function disableDaysBeforeToday$prime(_date, _dates) {
  while(true) {
    var dates = _dates;
    var date = _date;
    var d = date.getDate();
    if (d !== 1.0) {
      _dates = /* :: */[
        date,
        dates
      ];
      _date = new Date(date.getFullYear(), date.getMonth(), d - 1.0);
      continue ;
    } else {
      return /* :: */[
              date,
              dates
            ];
    }
  };
}

function disableDaysBeforeToday(param) {
  var date = new Date();
  return $$Array.of_list(disableDaysBeforeToday$prime(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1.0), /* [] */0));
}

exports.disableDaysBeforeToday$prime = disableDaysBeforeToday$prime;
exports.disableDaysBeforeToday = disableDaysBeforeToday;
/* No side effect */
