'use strict';

var Axios = require("bs-axios/src/axios.js");
var Axios$1 = require("axios");
var React = require("react");

var context = React.createContext(Axios$1.create({
          headers: Axios.$$Headers.fromObj({
                "content-type": "application/json"
              })
        }));

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

exports.context = context;
exports.makeProps = makeProps;
exports.make = make;
/* context Not a pure module */
