'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function transformValue(dict, key, decode, transform) {
  var dictValue = Js_dict.get(dict, key);
  if (dictValue !== undefined) {
    var match = Js_json.decodeString(Caml_option.valFromOption(dictValue));
    if (match !== undefined) {
      dict[key] = Curry._1(transform, match);
      return /* () */0;
    } else {
      return /* () */0;
    }
  } else {
    return /* () */0;
  }
}

function get(dict, key) {
  var match = Js_dict.get(dict, key);
  if (match !== undefined) {
    var match$1 = Js_json.decodeString(Caml_option.valFromOption(match));
    if (match$1 !== undefined) {
      return match$1;
    } else {
      return ;
    }
  }
  
}

function getNumber(dict, key) {
  var match = Js_dict.get(dict, key);
  if (match !== undefined) {
    var match$1 = Js_json.decodeNumber(Caml_option.valFromOption(match));
    if (match$1 !== undefined) {
      return match$1;
    } else {
      return ;
    }
  }
  
}

function getOrEmpty(dict, key) {
  var match = Js_dict.get(dict, key);
  if (match !== undefined) {
    var match$1 = Js_json.decodeString(Caml_option.valFromOption(match));
    if (match$1 !== undefined) {
      return match$1;
    } else {
      return "";
    }
  } else {
    return "";
  }
}

function getOr(dict, key, $$default) {
  var match = Js_dict.get(dict, key);
  if (match !== undefined) {
    var match$1 = Js_json.decodeString(Caml_option.valFromOption(match));
    if (match$1 !== undefined) {
      return match$1;
    } else {
      return $$default;
    }
  } else {
    return $$default;
  }
}

function getBoolOrFalse(dict, key) {
  var match = Js_dict.get(dict, key);
  if (match !== undefined) {
    var match$1 = Js_json.decodeBoolean(Caml_option.valFromOption(match));
    if (match$1 !== undefined) {
      return match$1;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

exports.transformValue = transformValue;
exports.get = get;
exports.getNumber = getNumber;
exports.getOrEmpty = getOrEmpty;
exports.getOr = getOr;
exports.getBoolOrFalse = getBoolOrFalse;
/* No side effect */
