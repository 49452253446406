'use strict';

var React = require("react");

function PageContainer(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "my-8 mx-4"
            }, children);
}

var make = PageContainer;

exports.make = make;
/* react Not a pure module */
