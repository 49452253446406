'use strict';

var React = require("react");

var context = React.createContext("");

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

exports.context = context;
exports.makeProps = makeProps;
exports.make = make;
/* context Not a pure module */
