'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var H1$BycratesPlatformUi = require("../Headings/H1.bs.js");
var ApiC$BycratesPlatformUi = require("../Api/ApiC.bs.js");
var Card$BycratesPlatformUi = require("../Card/Card.bs.js");
var Loader$BycratesPlatformUi = require("../Loader/Loader.bs.js");
var PageContainer$BycratesPlatformUi = require("../PageContainer/PageContainer.bs.js");

function FormSchema(Props) {
  var id = Props.id;
  Props.mode;
  var match = React.useState((function () {
          return /* Loading */0;
        }));
  var setSelectedForm = match[1];
  var selectedForm = match[0];
  var api = React.useContext(ApiC$BycratesPlatformUi.context);
  React.useEffect((function () {
          api.get("/forms/" + id).then((function (x) {
                  return api.get("/forms/" + (id + "/fields")).then((function (y) {
                                return Promise.resolve(Curry._1(setSelectedForm, (function (param) {
                                                  return /* Selected */[/* tuple */[
                                                            x.data.data,
                                                            y.data.data
                                                          ]];
                                                })));
                              }));
                }));
          return ;
        }), ([]));
  if (selectedForm) {
    var match$1 = selectedForm[0];
    return React.createElement(PageContainer$BycratesPlatformUi.make, {
                children: null
              }, React.createElement(H1$BycratesPlatformUi.make, {
                    children: "Skjema - " + match$1[0].name
                  }), React.createElement("div", {
                    className: "mt-4 space-y-6"
                  }, $$Array.map((function (field) {
                          return React.createElement(Card$BycratesPlatformUi.make, {
                                      children: field.question
                                    });
                        }), match$1[1])));
  } else {
    return React.createElement(Loader$BycratesPlatformUi.make, { });
  }
}

var make = FormSchema;

exports.make = make;
/* react Not a pure module */
