'use strict';

var React = require("react");

function H2(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("h2", {
              className: "font-bold text-xl " + className
            }, children);
}

var make = H2;

exports.make = make;
/* react Not a pure module */
