'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Axios = require("bs-axios/src/axios.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var H3$BycratesPlatformUi = require("../Headings/H3.bs.js");
var ApiC$BycratesPlatformUi = require("../Api/ApiC.bs.js");
var Card$BycratesPlatformUi = require("../Card/Card.bs.js");
var Alert$BycratesPlatformUi = require("../Alert/Alert.bs.js");
var Input$BycratesPlatformUi = require("../Input/Input.bs.js");
var Button$BycratesPlatformUi = require("../Button/Button.bs.js");
var Loader$BycratesPlatformUi = require("../Loader/Loader.bs.js");
var ProjectC$BycratesPlatformUi = require("../ProjectC.bs.js");
var ListUtils$BycratesPlatformUi = require("../Utils/ListUtils.bs.js");
var AxiosUtils$BycratesPlatformUi = require("../Utils/AxiosUtils.bs.js");
var RadioGroup$BycratesPlatformUi = require("../Radio/RadioGroup.bs.js");
var RouterLink$BycratesPlatformUi = require("../RouterLink/RouterLink.bs.js");
var DayPickerJs = require("react-day-picker/DayPicker.js");
var FlexActions$BycratesPlatformUi = require("../Flex/FlexActions.bs.js");
var JsonDictUtils$BycratesPlatformUi = require("../Utils/JsonDictUtils.bs.js");
var PageContainer$BycratesPlatformUi = require("../PageContainer/PageContainer.bs.js");
var ResourceUtils$BycratesPlatformUi = require("./ResourceUtils.bs.js");
var ReactDayPicker_DayPicker$BycratesPlatformUi = require("../bindings/ReactDayPicker/ReactDayPicker_DayPicker.bs.js");

function makeFormInput(noAutoMbOpt, requiredOpt, type_Opt, onChangeSearchOpt, autoCompleteOpt, patternOpt, patternTitleOpt, showClassNameOpt, boxesOpt, transformGetOpt, name, placeholder) {
  var noAutoMb = noAutoMbOpt !== undefined ? noAutoMbOpt : false;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var type_ = type_Opt !== undefined ? type_Opt : "text";
  var onChangeSearch = onChangeSearchOpt !== undefined ? Caml_option.valFromOption(onChangeSearchOpt) : undefined;
  var autoComplete = autoCompleteOpt !== undefined ? autoCompleteOpt : "on";
  var pattern = patternOpt !== undefined ? patternOpt : ".*";
  var patternTitle = patternTitleOpt !== undefined ? patternTitleOpt : "";
  var showClassName = showClassNameOpt !== undefined ? showClassNameOpt : "";
  var boxes = boxesOpt !== undefined ? boxesOpt : /* [] */0;
  var transformGet = transformGetOpt !== undefined ? transformGetOpt : (function (param, param$1) {
        return ;
      });
  return {
          name: name,
          placeholder: placeholder,
          noAutoMb: noAutoMb,
          required: required,
          autoComplete: autoComplete,
          type_: type_,
          pattern: pattern,
          patternTitle: patternTitle,
          onChangeSearch: onChangeSearch,
          showClassName: showClassName,
          boxes: boxes,
          transformGet: transformGet
        };
}

var defaultTransformer = Axios.makeRequestTransformer1((function (x, param) {
        return JSON.stringify(x);
      }));

function ResourceShow(Props) {
  var showSavedNoRedirectOpt = Props.showSavedNoRedirect;
  var header = Props.header;
  var type_ = Props.type_;
  var formBoxes = Props.formBoxes;
  var resourcePath = Props.resourcePath;
  var transformRequestOpt = Props.transformRequest;
  var noLoadOpt = Props.noLoad;
  var submitButtonLabel = Props.submitButtonLabel;
  var deleteButtonLabel = Props.deleteButtonLabel;
  var method_Opt = Props.method;
  var customSection = Props.customSection;
  var hideActionsIf = Props.hideActionsIf;
  var hideEditOpt = Props.hideEdit;
  var customActions = Props.customActions;
  var errorMessagesOpt = Props.errorMessages;
  var deleteMessageOpt = Props.deleteMessage;
  var showSavedNoRedirect = showSavedNoRedirectOpt !== undefined ? showSavedNoRedirectOpt : false;
  var transformRequest = transformRequestOpt !== undefined ? Caml_option.valFromOption(transformRequestOpt) : defaultTransformer;
  var noLoad = noLoadOpt !== undefined ? noLoadOpt : false;
  var method_ = method_Opt !== undefined ? method_Opt : type_;
  var hideEdit = hideEditOpt !== undefined ? hideEditOpt : false;
  var errorMessages = errorMessagesOpt !== undefined ? Caml_option.valFromOption(errorMessagesOpt) : ({ });
  var deleteMessage = deleteMessageOpt !== undefined ? deleteMessageOpt : "Er du sikker på at du vil slette denne ressursen? Denne handlingen er ikke reverserbar";
  var match = React.useState((function () {
          return /* Loading */0;
        }));
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState((function () {
          return ;
        }));
  var setErrorMessage = match$1[1];
  var errorMessage = match$1[0];
  var match$2 = React.useState((function () {
          return false;
        }));
  var setSaved = match$2[1];
  var match$3 = React.useState((function () {
          return false;
        }));
  var setSubmitted = match$3[1];
  React.useContext(ProjectC$BycratesPlatformUi.context);
  var api = React.useContext(ApiC$BycratesPlatformUi.context);
  var url = ReasonReactRouter.useUrl(undefined, /* () */0);
  React.useEffect((function () {
          if (type_ !== 1) {
            if (noLoad) {
              Curry._1(setState, (function (param) {
                      return /* EditMode */[{ }];
                    }));
            } else {
              api.get(resourcePath).then((function (res) {
                      return Promise.resolve(Curry._1(setState, (function (param) {
                                        return /* EditMode */[res.data.data];
                                      })));
                    }));
            }
          } else {
            Curry._1(setState, (function (param) {
                    return /* EditMode */[{ }];
                  }));
          }
          return ;
        }), ([]));
  var validateForm = function (param) {
    return !List.exists((function (x) {
                  return x === false;
                }), List.flatten(List.map((function (box) {
                          return List.map((function (input) {
                                        if (input.required) {
                                          if (state) {
                                            return Js_dict.get(state[0], input.name) !== undefined;
                                          } else {
                                            return false;
                                          }
                                        } else {
                                          return true;
                                        }
                                      }), box.inputs);
                        }), formBoxes)));
  };
  var onSubmit = function (e) {
    e.preventDefault();
    Curry._1(setErrorMessage, (function (param) {
            return ;
          }));
    Curry._1(setSubmitted, (function (param) {
            return true;
          }));
    if (type_ === /* Show */0) {
      RouterLink$BycratesPlatformUi.pushRouter(undefined, Pervasives.$at(url.path, /* :: */[
                "edit",
                /* [] */0
              ]), url.search);
      return /* () */0;
    } else if (validateForm(/* () */0)) {
      var instance;
      if (state) {
        var m = state[0];
        instance = method_ !== 1 ? api.put(resourcePath, ResourceUtils$BycratesPlatformUi.convertStateToJsT(m), {
                transformRequest: transformRequest
              }) : api.post(resourcePath, ResourceUtils$BycratesPlatformUi.convertStateToJsT(m), {
                transformRequest: transformRequest
              });
      } else {
        instance = api.get("");
      }
      instance.then((function (res) {
                Curry._1(setErrorMessage, (function (param) {
                        return ;
                      }));
                switch (type_) {
                  case /* Show */0 :
                      break;
                  case /* Create */1 :
                      RouterLink$BycratesPlatformUi.pushRouter(undefined, /* :: */[
                            resourcePath.replace("/", ""),
                            /* :: */[
                              res.data.data.id,
                              /* [] */0
                            ]
                          ], url.search);
                      break;
                  case /* Edit */2 :
                      if (showSavedNoRedirect) {
                        Curry._1(setSaved, (function (param) {
                                return true;
                              }));
                      } else {
                        RouterLink$BycratesPlatformUi.pushRouter(undefined, List.filter((function (x) {
                                      return x !== "edit";
                                    }))(url.path), url.search);
                      }
                      break;
                  
                }
                return Promise.resolve(Curry._1(setSubmitted, (function (param) {
                                  return false;
                                })));
              })).catch((function (err) {
              var errResponse = AxiosUtils$BycratesPlatformUi.getErrorResponseData(err);
              Curry._1(setSaved, (function (param) {
                      return false;
                    }));
              switch (type_) {
                case /* Show */0 :
                    break;
                case /* Create */1 :
                    Curry._1(setErrorMessage, (function (param) {
                            return JsonDictUtils$BycratesPlatformUi.getOr(errorMessages, errResponse.code, "Kunne ikke opprette ressurs");
                          }));
                    break;
                case /* Edit */2 :
                    Curry._1(setErrorMessage, (function (param) {
                            return JsonDictUtils$BycratesPlatformUi.getOr(errorMessages, errResponse.code, "Kunne ikke lagre ressurs");
                          }));
                    break;
                
              }
              return Promise.resolve(Curry._1(setSubmitted, (function (param) {
                                return false;
                              })));
            }));
      return /* () */0;
    } else {
      Curry._1(setErrorMessage, (function (param) {
              return "Alle obligatoriske felt ikke utfylt";
            }));
      return Curry._1(setSubmitted, (function (param) {
                    return false;
                  }));
    }
  };
  var commonChange = function (id, value) {
    return Curry._1(setState, (function (p) {
                  if (p) {
                    var x = p[0];
                    x[id] = value.length === 0 ? null : value;
                    return /* EditMode */[x];
                  } else {
                    return p;
                  }
                }));
  };
  var onChangeSearchSelect = commonChange;
  var $$delete = function (param) {
    if (confirm(deleteMessage)) {
      api.delete(resourcePath).then((function (param) {
                return Promise.resolve(RouterLink$BycratesPlatformUi.pushRouter(undefined, /* :: */[
                                List.nth(url.path, 0),
                                /* [] */0
                              ], url.search));
              })).catch((function (param) {
              return Promise.resolve(Curry._1(setErrorMessage, (function (param) {
                                return "Kunne ikke slette ressurs";
                              })));
            }));
      return /* () */0;
    } else {
      return /* () */0;
    }
  };
  var blank = React.createElement("span", {
        className: "italic"
      }, "Blank");
  var customElement = customSection !== undefined ? Curry._1(customSection, state) : null;
  var shouldHideActions = function (dict) {
    if (hideActionsIf !== undefined) {
      return Curry._1(hideActionsIf, dict);
    } else {
      return false;
    }
  };
  var tmp;
  if (state) {
    var x = state[0];
    var tmp$1;
    if (shouldHideActions(x)) {
      tmp$1 = null;
    } else {
      var tmp$2;
      if (hideEdit) {
        tmp$2 = null;
      } else {
        var tmp$3;
        if (submitButtonLabel !== undefined) {
          tmp$3 = submitButtonLabel;
        } else {
          switch (type_) {
            case /* Show */0 :
                tmp$3 = "Endre";
                break;
            case /* Create */1 :
                tmp$3 = "Opprett";
                break;
            case /* Edit */2 :
                tmp$3 = "Lagre";
                break;
            
          }
        }
        tmp$2 = React.createElement(Button$BycratesPlatformUi.make, {
              type_: "submit",
              label: tmp$3,
              disabled: match$3[0]
            });
      }
      tmp$1 = React.createElement(React.Fragment, undefined, tmp$2, type_ === /* Show */0 ? React.createElement("div", {
                  className: "text-red-500 text-sm cursor-pointer",
                  onClick: $$delete
                }, deleteButtonLabel !== undefined ? deleteButtonLabel : "Slett") : null, customActions !== undefined ? Curry._1(customActions, x) : null);
    }
    tmp = React.createElement("div", undefined, React.createElement("form", {
              onSubmit: onSubmit
            }, React.createElement(FlexActions$BycratesPlatformUi.make, {
                  header: header,
                  children: tmp$1
                }), errorMessage !== undefined ? React.createElement(Alert$BycratesPlatformUi.make, {
                    className: "mb-8",
                    children: errorMessage
                  }) : null, match$2[0] ? React.createElement(Alert$BycratesPlatformUi.make, {
                    className: "mb-8",
                    children: "Lagret",
                    type_: /* Success */0
                  }) : null, $$Array.of_list(List.map((function (chunk) {
                        return React.createElement("div", {
                                    className: "w-full flex justify-between items-stretch"
                                  }, $$Array.of_list(List.mapi((function (i, box) {
                                              return React.createElement(Card$BycratesPlatformUi.make, {
                                                          children: null,
                                                          className: "max-w-xl " + (
                                                            i === 0 ? "mr-4" : "ml-4"
                                                          )
                                                        }, React.createElement(H3$BycratesPlatformUi.make, {
                                                              className: "mb-8",
                                                              children: box.header
                                                            }), React.createElement("div", {
                                                              className: type_ !== 0 ? "" : "grid grid-cols-2"
                                                            }, $$Array.of_list(List.map((function (inpt) {
                                                                        if (type_ !== 0) {
                                                                          var match = inpt.type_;
                                                                          switch (match) {
                                                                            case "date" :
                                                                                var match$1 = JsonDictUtils$BycratesPlatformUi.get(x, inpt.name);
                                                                                return React.createElement("div", undefined, React.createElement("div", {
                                                                                                className: "text-xs text-gray-800 pb-1"
                                                                                              }, inpt.placeholder), React.createElement(DayPickerJs.default, {
                                                                                                onDayClick: (function (x) {
                                                                                                    var id = inpt.name;
                                                                                                    var date = x;
                                                                                                    return Curry._1(setState, (function (p) {
                                                                                                                  if (p) {
                                                                                                                    var x = p[0];
                                                                                                                    x[id] = date.toISOString();
                                                                                                                    return /* EditMode */[x];
                                                                                                                  } else {
                                                                                                                    return p;
                                                                                                                  }
                                                                                                                }));
                                                                                                  }),
                                                                                                selectedDays: match$1 !== undefined ? Caml_array.caml_make_vect(1, new Date(match$1)) : Caml_array.caml_make_vect(0, new Date()),
                                                                                                fromMonth: new Date(),
                                                                                                disabledDays: ReactDayPicker_DayPicker$BycratesPlatformUi.disableDaysBeforeToday(/* () */0)
                                                                                              }));
                                                                            case "radio" :
                                                                                return React.createElement("div", undefined, React.createElement("div", {
                                                                                                className: "text-xs text-gray-800 pb-1 mb-2"
                                                                                              }, inpt.placeholder), React.createElement(RadioGroup$BycratesPlatformUi.make, {
                                                                                                name: inpt.name,
                                                                                                boxes: inpt.boxes,
                                                                                                setValue: (function (x) {
                                                                                                    var id = inpt.name;
                                                                                                    var value = x;
                                                                                                    return Curry._1(setState, (function (p) {
                                                                                                                  if (p) {
                                                                                                                    var x = p[0];
                                                                                                                    x[id] = value;
                                                                                                                    return /* EditMode */[x];
                                                                                                                  } else {
                                                                                                                    return p;
                                                                                                                  }
                                                                                                                }));
                                                                                                  })
                                                                                              }));
                                                                            default:
                                                                              var match$2 = JsonDictUtils$BycratesPlatformUi.get(x, inpt.name);
                                                                              return React.createElement(Input$BycratesPlatformUi.make, {
                                                                                          type_: inpt.type_,
                                                                                          placeholder: inpt.placeholder,
                                                                                          name: inpt.name,
                                                                                          autoMb: !inpt.noAutoMb,
                                                                                          required: inpt.required,
                                                                                          autoComplete: inpt.autoComplete,
                                                                                          onChange: (function (e) {
                                                                                              var match = inpt.onChangeSearch;
                                                                                              if (match !== undefined) {
                                                                                                return /* () */0;
                                                                                              } else {
                                                                                                var e$1 = e;
                                                                                                var target = e$1.currentTarget;
                                                                                                return commonChange(target.id, target.value);
                                                                                              }
                                                                                            }),
                                                                                          onChangeSearch: inpt.onChangeSearch,
                                                                                          onChangeSearchSelect: onChangeSearchSelect,
                                                                                          value: match$2 !== undefined ? match$2 : "",
                                                                                          pattern: inpt.pattern,
                                                                                          title: inpt.patternTitle
                                                                                        });
                                                                          }
                                                                        } else {
                                                                          var match$3 = Js_dict.get(x, inpt.name);
                                                                          var tmp;
                                                                          if (match$3 !== undefined) {
                                                                            var match$4 = Curry._2(inpt.transformGet, Caml_option.valFromOption(match$3), x);
                                                                            if (match$4 !== undefined) {
                                                                              tmp = match$4;
                                                                            } else {
                                                                              var match$5 = JsonDictUtils$BycratesPlatformUi.get(x, inpt.name);
                                                                              if (match$5 !== undefined) {
                                                                                var n = match$5;
                                                                                var match$6 = inpt.type_;
                                                                                tmp = match$6 === "date" ? new Date(n).toLocaleString() : n;
                                                                              } else {
                                                                                tmp = blank;
                                                                              }
                                                                            }
                                                                          } else {
                                                                            tmp = blank;
                                                                          }
                                                                          return React.createElement("div", {
                                                                                      className: inpt.showClassName + " mb-4"
                                                                                    }, React.createElement("div", {
                                                                                          className: "font-medium text-normal"
                                                                                        }, inpt.placeholder), React.createElement("div", {
                                                                                          className: "text-normal"
                                                                                        }, tmp));
                                                                        }
                                                                      }), box.inputs))));
                                            }), chunk)));
                      }), ListUtils$BycratesPlatformUi.chunks(formBoxes, 2)))), customElement);
  } else {
    tmp = React.createElement(Loader$BycratesPlatformUi.make, { });
  }
  return React.createElement(PageContainer$BycratesPlatformUi.make, {
              children: tmp
            });
}

var make = ResourceShow;

exports.makeFormInput = makeFormInput;
exports.defaultTransformer = defaultTransformer;
exports.make = make;
/* defaultTransformer Not a pure module */
