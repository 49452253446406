'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function Input(Props) {
  var type_Opt = Props.type_;
  var placeholder = Props.placeholder;
  var name = Props.name;
  var classNameOpt = Props.className;
  var autoMbOpt = Props.autoMb;
  var autoFocusOpt = Props.autoFocus;
  var requiredOpt = Props.required;
  var autoCompleteOpt = Props.autoComplete;
  var onChangeOpt = Props.onChange;
  var onChangeSearchOpt = Props.onChangeSearch;
  var onChangeSearchSelectOpt = Props.onChangeSearchSelect;
  var valueOpt = Props.value;
  var patternOpt = Props.pattern;
  var titleOpt = Props.title;
  var initialValueOpt = Props.initialValue;
  var originalPlaceholderOpt = Props.originalPlaceholder;
  var type_ = type_Opt !== undefined ? type_Opt : "text";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var autoMb = autoMbOpt !== undefined ? autoMbOpt : false;
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var autoComplete = autoCompleteOpt !== undefined ? autoCompleteOpt : "on";
  var onChange = onChangeOpt !== undefined ? onChangeOpt : (function (param) {
        return /* () */0;
      });
  var onChangeSearch = onChangeSearchOpt !== undefined ? Caml_option.valFromOption(onChangeSearchOpt) : undefined;
  var onChangeSearchSelect = onChangeSearchSelectOpt !== undefined ? onChangeSearchSelectOpt : (function (param, param$1) {
        return /* () */0;
      });
  var value = valueOpt !== undefined ? valueOpt : "";
  var pattern = patternOpt !== undefined ? patternOpt : ".*";
  var title = titleOpt !== undefined ? titleOpt : "";
  var initialValue = initialValueOpt !== undefined ? initialValueOpt : "";
  var originalPlaceholder = originalPlaceholderOpt !== undefined ? originalPlaceholderOpt : false;
  var match = React.useState((function () {
          return /* NoEntries */0;
        }));
  var setSearchEntries = match[1];
  var searchEntries = match[0];
  var match$1 = React.useState((function () {
          return initialValue;
        }));
  var setStateValue = match$1[1];
  var stateValue = match$1[0];
  var containerRef = React.useRef(null);
  var resetSearchEntries = function (e) {
    var target = e.target;
    var match = containerRef.current;
    if (!(match == null) && target.contains(match)) {
      return Curry._1(setSearchEntries, (function (param) {
                    return /* NoEntries */0;
                  }));
    } else {
      return /* () */0;
    }
  };
  React.useEffect((function () {
          Curry._1(setStateValue, (function (param) {
                  return value;
                }));
          document.addEventListener("click", resetSearchEntries);
          return (function (param) {
                    document.removeEventListener("click", resetSearchEntries);
                    return /* () */0;
                  });
        }), ([]));
  React.useEffect((function () {
          if (searchEntries) {
            if (searchEntries[0]) {
              
            } else {
              Curry._1(setSearchEntries, (function (param) {
                      return /* NoEntries */0;
                    }));
            }
          }
          return ;
        }), Caml_array.caml_make_vect(1, searchEntries));
  React.useEffect((function () {
          if (stateValue === "") {
            Curry._1(setSearchEntries, (function (param) {
                    return /* NoEntries */0;
                  }));
          }
          return ;
        }), Caml_array.caml_make_vect(1, stateValue));
  var onBlur = function (param) {
    return /* () */0;
  };
  var onChangeInterim = function (e) {
    var targetValue = e.currentTarget.value;
    Curry._1(setStateValue, (function (param) {
            return targetValue;
          }));
    Curry._1(onChange, e);
    if (onChangeSearch !== undefined) {
      var entries = Curry._1(onChangeSearch, targetValue);
      if (entries.tag) {
        entries[0].then((function (m) {
                return Promise.resolve(Curry._1(setSearchEntries, (function (param) {
                                  return /* Entries */[m];
                                })));
              }));
        return /* () */0;
      } else {
        var x = entries[0];
        return Curry._1(setSearchEntries, (function (param) {
                      return /* Entries */[x];
                    }));
      }
    } else {
      return /* () */0;
    }
  };
  var commonSearchSelect = function (accessKey, sValue, targetValue) {
    Curry._1(setStateValue, (function (param) {
            return sValue;
          }));
    Curry._1(setSearchEntries, (function (param) {
            return /* NoEntries */0;
          }));
    return Curry._2(onChangeSearchSelect, accessKey, targetValue);
  };
  var onChangeSearchSelectInterim = function (e) {
    var target = e.currentTarget;
    var sValue = target.innerHTML;
    var targetValue = target.id;
    return commonSearchSelect(target.accessKey, sValue, targetValue);
  };
  var onKeyPressSearchSelect = function (e) {
    var target = e.currentTarget;
    var sValue = target.innerHTML;
    var targetValue = target.id;
    return commonSearchSelect(target.accessKey, sValue, targetValue);
  };
  return React.createElement("div", {
              ref: containerRef,
              className: "flex flex-col-reverse relative" + ((
                  autoMb ? " mb-4 " : " "
                ) + className),
              onBlur: onBlur
            }, React.createElement("input", {
                  className: "bg-white border border-gray-200 p-2 px-4 w-full rounded text-sm transition duration-100 focus:outline-none focus:border-gray-400",
                  id: name,
                  title: title,
                  autoComplete: autoComplete,
                  autoFocus: autoFocus,
                  pattern: pattern,
                  placeholder: originalPlaceholder ? placeholder : "",
                  required: required,
                  type: type_,
                  value: stateValue,
                  onChange: onChangeInterim
                }), originalPlaceholder ? null : React.createElement("label", {
                    className: "text-xs text-gray-800 pb-1",
                    htmlFor: name
                  }, placeholder), searchEntries ? React.createElement("div", {
                    className: "w-full bg-white absolute overflow-scroll rounded shadow rounded-tr-none rounded-tl-none z-20",
                    style: {
                      top: "100%"
                    }
                  }, $$Array.of_list(List.map((function (entry) {
                              return React.createElement("div", {
                                          accessKey: name,
                                          className: "p-2 px-4 cursor-pointer focus:bg-primary-opac hover:bg-primary-opac",
                                          id: entry.value,
                                          tabIndex: 0,
                                          onKeyPress: onKeyPressSearchSelect,
                                          onClick: onChangeSearchSelectInterim
                                        }, entry.label);
                            }), searchEntries[0]))) : null);
}

var make = Input;

exports.make = make;
/* react Not a pure module */
