'use strict';

var React = require("react");
var Contacts$BycratesPlatformUi = require("./Contacts.bs.js");
var ResourceShow$BycratesPlatformUi = require("../Resource/ResourceShow.bs.js");

function EditContact(Props) {
  var id = Props.id;
  return React.createElement(ResourceShow$BycratesPlatformUi.make, {
              header: "Endre kontakt",
              type_: /* Edit */2,
              formBoxes: Contacts$BycratesPlatformUi.formBoxes,
              resourcePath: "/contacts/" + id
            });
}

var make = EditContact;

exports.make = make;
/* react Not a pure module */
