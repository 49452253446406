'use strict';

var List = require("bs-platform/lib/js/list.js");

function chunks$prime(l, q, _ret, _i) {
  while(true) {
    var i = _i;
    var ret = _ret;
    if (i === List.length(l)) {
      if (ret && !ret[0]) {
        return ret[1];
      } else {
        return ret;
      }
    } else if (ret) {
      var tl = ret[1];
      var hd = ret[0];
      _i = i + 1 | 0;
      _ret = List.length(hd) === q ? /* :: */[
          /* :: */[
            List.nth(l, i),
            /* [] */0
          ],
          /* :: */[
            hd,
            tl
          ]
        ] : /* :: */[
          List.rev_append(hd, /* :: */[
                List.nth(l, i),
                /* [] */0
              ]),
          tl
        ];
      continue ;
    } else {
      return ret;
    }
  };
}

function chunks(l, q) {
  if (l) {
    return List.rev(chunks$prime(l, q, /* :: */[
                    /* [] */0,
                    /* [] */0
                  ], 0));
  } else {
    return /* [] */0;
  }
}

exports.chunks$prime = chunks$prime;
exports.chunks = chunks;
/* No side effect */
