'use strict';

var React = require("react");

function Loader(Props) {
  return React.createElement("div", {
              className: "loader"
            });
}

var make = Loader;

exports.make = make;
/* react Not a pure module */
