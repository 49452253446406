'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Axios = require("bs-axios/src/axios.js");
var Block = require("bs-platform/lib/js/block.js");
var $$String = require("bs-platform/lib/js/string.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ResourceShow$BycratesPlatformUi = require("../Resource/ResourceShow.bs.js");
var JsonDictUtils$BycratesPlatformUi = require("../Utils/JsonDictUtils.bs.js");

var transformRequest = Axios.makeRequestTransformer1((function (data, param) {
        JsonDictUtils$BycratesPlatformUi.transformValue(data, "appointmentDuration", /* String */0, Caml_format.caml_float_of_string);
        JsonDictUtils$BycratesPlatformUi.transformValue(data, "appointmentTimestamp", /* String */0, (function (x) {
                var match = JsonDictUtils$BycratesPlatformUi.get(data, "appointmentHourMinutes");
                if (match !== undefined) {
                  var date = new Date(x);
                  var hhmm = $$String.split_on_char(/* ":" */58, match);
                  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), Caml_format.caml_float_of_string(List.hd(hhmm)), Caml_format.caml_float_of_string(List.nth(hhmm, 1))).toISOString();
                } else {
                  return null;
                }
              }));
        return JSON.stringify(data);
      }));

function contactIdFormInput(api) {
  return ResourceShow$BycratesPlatformUi.makeFormInput(undefined, true, undefined, Caml_option.some((function (value) {
                    return /* ListPromiseEntries */Block.__(1, [api.get("/contacts", {
                                      params: {
                                        "filter[fullName]": value
                                      }
                                    }).then((function (res) {
                                      return Promise.resolve($$Array.to_list($$Array.map((function (contact) {
                                                            return {
                                                                    label: contact.givenName + (" " + contact.familyName),
                                                                    value: contact.id
                                                                  };
                                                          }), res.data.data)));
                                    }))]);
                  })), "off", undefined, undefined, undefined, undefined, (function (param, dict) {
                return JsonDictUtils$BycratesPlatformUi.get(dict, "contactName");
              }), "contactId", "Kunde");
}

function appointmentHandlerInput(api) {
  return ResourceShow$BycratesPlatformUi.makeFormInput(undefined, true, undefined, Caml_option.some((function (value) {
                    return /* ListPromiseEntries */Block.__(1, [api.get("/users", {
                                      params: {
                                        "filter[role]": "appointment_handler",
                                        "filter[fullName]": value
                                      }
                                    }).then((function (res) {
                                      return Promise.resolve($$Array.to_list($$Array.map((function (user) {
                                                            return {
                                                                    label: user.givenName + (" " + (user.familyName + (" (" + (user.emailAddress + ")")))),
                                                                    value: user.id
                                                                  };
                                                          }), res.data.data)));
                                    }))]);
                  })), "off", undefined, undefined, undefined, undefined, (function (param, dict) {
                return JsonDictUtils$BycratesPlatformUi.get(dict, "appointmentHandlerName");
              }), "appointmentHandlerId", "Behandler");
}

function formBoxes(hideTimestampOpt, api) {
  var hideTimestamp = hideTimestampOpt !== undefined ? hideTimestampOpt : false;
  var ret_000 = {
    header: "Avtaledetaljer",
    inputs: /* :: */[
      contactIdFormInput(api),
      /* :: */[
        appointmentHandlerInput(api),
        /* :: */[
          ResourceShow$BycratesPlatformUi.makeFormInput(undefined, true, "number", undefined, undefined, undefined, undefined, undefined, undefined, (function (currentValue, param) {
                  var match = Js_json.decodeNumber(currentValue);
                  if (match !== undefined) {
                    return match.toString();
                  }
                  
                }), "appointmentDuration", "Varighet (minutter)"),
          /* [] */0
        ]
      ]
    ]
  };
  var ret_001 = /* :: */[
    {
      header: "Avtaletidspunkt",
      inputs: /* :: */[
        ResourceShow$BycratesPlatformUi.makeFormInput(undefined, true, "date", undefined, undefined, undefined, undefined, undefined, undefined, undefined, "appointmentTimestamp", "Dato"),
        /* [] */0
      ]
    },
    /* [] */0
  ];
  var ret = /* :: */[
    ret_000,
    ret_001
  ];
  if (hideTimestamp) {
    return ret;
  } else {
    var m = List.nth(ret, 1);
    var n_header = m.header;
    var n_inputs = Pervasives.$at(m.inputs, /* :: */[
          ResourceShow$BycratesPlatformUi.makeFormInput(undefined, true, undefined, undefined, undefined, "\\d\\d:\\d\\d", "Feltet krever formatet HH:MM", undefined, undefined, undefined, "appointmentHourMinutes", "Tidspunkt (HH:MM)"),
          /* [] */0
        ]);
    var n = {
      header: n_header,
      inputs: n_inputs
    };
    return /* :: */[
            List.hd(ret),
            /* :: */[
              n,
              /* [] */0
            ]
          ];
  }
}

function statusToString(s) {
  if (s[0] !== undefined) {
    if (s[1] !== undefined) {
      return "Aktiv";
    } else {
      return "Avbestilt";
    }
  } else if (s[1] !== undefined) {
    return "Ikke møtt";
  } else if (s[2]) {
    return "Ferdig";
  } else {
    return "Aktiv";
  }
}

exports.transformRequest = transformRequest;
exports.contactIdFormInput = contactIdFormInput;
exports.appointmentHandlerInput = appointmentHandlerInput;
exports.formBoxes = formBoxes;
exports.statusToString = statusToString;
/* transformRequest Not a pure module */
