'use strict';

var React = require("react");

function H1(Props) {
  var lightOpt = Props.light;
  var classNameOpt = Props.className;
  var children = Props.children;
  var light = lightOpt !== undefined ? lightOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("h1", {
              className: "text-2xl " + ((
                  light ? "font-light " : "font-bold"
                ) + className)
            }, children);
}

var make = H1;

exports.make = make;
/* react Not a pure module */
