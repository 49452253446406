'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Axios = require("axios");
var React = require("react");
var Card$BycratesPlatformUi = require("../Card/Card.bs.js");
var Alert$BycratesPlatformUi = require("../Alert/Alert.bs.js");
var Input$BycratesPlatformUi = require("../Input/Input.bs.js");
var Button$BycratesPlatformUi = require("../Button/Button.bs.js");

function LoginPage(Props) {
  var match = React.useState((function () {
          return false;
        }));
  var setInvalidCombination = match[1];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setEmail = match$1[1];
  var email = match$1[0];
  var match$2 = React.useState((function () {
          return "";
        }));
  var setPassword = match$2[1];
  var password = match$2[0];
  var match$3 = React.useState((function () {
          return false;
        }));
  var setDidSubmit = match$3[1];
  var login = function (e) {
    e.preventDefault();
    Curry._1(setDidSubmit, (function (param) {
            return true;
          }));
    var data = {
      email: email,
      password: password
    };
    Axios.post("/nhask_auth", data).then((function (param) {
              return Promise.resolve((window.location.reload(), /* () */0));
            })).catch((function (param) {
            Curry._1(setInvalidCombination, (function (param) {
                    return true;
                  }));
            return Promise.resolve(Curry._1(setDidSubmit, (function (param) {
                              return false;
                            })));
          }));
    return /* () */0;
  };
  return React.createElement("div", {
              className: "max-w-md w-full h-full"
            }, React.createElement(Card$BycratesPlatformUi.make, {
                  children: React.createElement("div", undefined, match[0] ? React.createElement(Alert$BycratesPlatformUi.make, {
                              className: "mb-6",
                              children: "Ugyldig kombinasjon"
                            }) : null, React.createElement("form", {
                            onSubmit: login
                          }, React.createElement(Input$BycratesPlatformUi.make, {
                                placeholder: "E-post",
                                name: "email",
                                className: "mb-6",
                                autoFocus: true,
                                required: true,
                                onChange: (function (e) {
                                    return Curry._1(setEmail, e.target.value);
                                  })
                              }), React.createElement(Input$BycratesPlatformUi.make, {
                                type_: "password",
                                placeholder: "Passord",
                                name: "password",
                                className: "mb-8",
                                required: true,
                                onChange: (function (e) {
                                    return Curry._1(setPassword, e.target.value);
                                  })
                              }), React.createElement(Button$BycratesPlatformUi.make, {
                                type_: "submit",
                                label: "Logg inn",
                                disabled: match$3[0]
                              })))
                }));
}

var make = LoginPage;

exports.make = make;
/* axios Not a pure module */
